<template>
  <div class="error">
    <p>Halaman tidak ditemukan!</p>
  </div>
</template>

<style>
.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #FCFAF9;
}

.error p {
  font-size: 1.8rem;
  color: #107c5c;
}
</style>